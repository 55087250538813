<template>
  <div class="detail-row-class">
    <div class="row">
      <div class="col-6">
        <p class="small"><b>CONTENT</b></p>
        <div class="mobile-preview"
             v-if="rowData.platform === 'mobile'">
          <p>{{ rowData.content }}</p>
        </div>
        <div class="website-preview" v-else>
          <div class="announcement" v-html="content"></div>
        </div>
      </div>
      <div class="col-3">
        <div>
          <p class="small"><b>SCHEDULE</b></p>
          <p class="small mb-1">
            <b>Selected Timezone: </b>
            <span v-if="rowData.timezone">{{ rowData.timezone }}</span>
            <span v-else> Not set </span>
          </p>
          <p class="small mb-1">
            <b>Start:</b>
            <span v-if="rowData.startDate || rowData.startTime">
              {{ rowData.startDateDisp }}
            </span>
            <span v-else> Not set </span>
          </p>
          <p class="small">
            <b>End:</b>
            <span v-if="rowData.endDate || rowData.endTime">
              {{ rowData.endDateDisp }}
            </span>
            <span v-else> Not set </span>
          </p>
          <p class="small">
            <b>Created:</b> {{ rowData.createdDate }}
          </p>
        </div>
      </div>
      <div class="col-3">
        <div v-if="rowData.recipient">
            <p class="small"><b>RECIPIENT</b></p>
            <div v-if="rowData.filter === 'location'">
              <p class="small" v-if="rowData.recipient.country.length > 0">
                <b>Country: </b>
                {{ rowData.recipient.country[0].name }}
              </p>
              <p class="small" v-if="rowData.recipient.state.length > 0">
                <b>State: </b>
                <ul>
                  <li v-for="(state, index) in rowData.recipient.state"
                      :key="index">
                    {{ state.name }}
                  </li>
                </ul>
              </p>
              <p class="small" v-if="rowData.recipient.city.length > 0">
                <b>City: </b>
                <ul>
                  <li v-for="(city, index) in rowData.recipient.city"
                      :key="index">
                    {{ city.name }}
                  </li>
                </ul>
              </p>
            </div>
            <div v-else>
              <p class="small" v-if="rowData.recipient.school">
                <b>School: </b>
                {{ schoolData.school_name }}
              </p>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
  import "quill-emoji/dist/quill-emoji.css";

  export default {
    name  : 'AnnouncementDetail',
    props : {
      rowData : {
        type     : Object,
        required : true,
      },
    },
    data() {
      return {
        schoolData : {},
      }
    },
    computed : {
      content() {
        let content = this.rowData.content;
        if (this.rowData.platform === 'website')
          content = this.rowData.content.replace(/\?/gi, '')
        
        return content;
      },
    },
    methods : {
      getSchoolName() {
        this.$http.get('api/school', {
          params : {
            id : this.rowData.recipient.school,
          },
        }).then(response => {
          this.schoolData = response.data;
        });
      },
    },
    mounted() {
      this.schoolData = {};
      if (this.rowData.recipient.school !== null)
        this.getSchoolName();
    },
  }
</script>

<style lang="scss" scoped>
  @import
    "../../../assets/scss/components/admin/announcement/announcement-detail";
</style>